<template>
  <div style="background-color: #FAFAFA;padding-top: 25px;padding-bottom: 110px">
    <div class="center" style="overflow: hidden">
      <!--左边区域-->
      <div style="margin-right: 20px;width: 850px;float: left">
        <!--分类-->
<!--        <div style="height: auto;">-->
<!--          <div style="height: 50px;line-height: 50px;font-size: 14px;background-color: white;padding: 0 5px">-->
<!--            <div class="div_classify">-->
<!--              <span style="padding-left: 10px" @click="changeTypeId(item.id)" v-for="item in typeList"-->
<!--                    :key="item.id" :class="{'span_select':currentTypeId === item.id}">{{item.name}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!--文章标签-->
        <div style="margin-top: 10px">
          <div style="width: 850px;float: left;min-height: 300px">
            <List item-layout="vertical" :split="false" :loading="knowledgeListLoading">
              <ListItem v-for="(item,index) in knowledgeList" :key="index" style="padding: 0">
                <div style="height:200px;margin-bottom:10px;background-color: white;padding-left: 20px">
                  <router-link :to="{path: 'knowledgeDetail',query:{'id':item.id}}" target="_blank" >
                    <div style="height: 50px;line-height: 50px;font-size: 18px;color: black;font-weight: bold"><span
                      style="cursor: pointer" @click="nowIndex = 2">{{item.title}}</span></div>
                  </router-link>
                  <div style="font-size: 12px;margin-bottom: 20px"><span>{{item.createdTime}}</span></div>
                  <div class="san_hang_sheng">
                    <span style="">{{item.introduction}}</span>
                  </div>
                </div>
              </ListItem>
            </List>

          </div>
        </div>


        <div style="overflow: hidden;width: 850px">
          <Page @on-change="onPageChange" :current="pageNum" :page-size="10" :total="knowledgeListTotalNum"
                show-elevator
                style="margin-top: 30px;float: right;margin-bottom: 30px"/>
        </div>
      </div>

      <patent-component/>

    </div>
  </div>
</template>

<script>

  import {
    getKnowledgeListById,
    getKnowledgeType
  } from "../../plugins/api/encyclopediaKnowledgeApi";
  import newestPatentAndAchievement from './newestPatentAndAchievement';

  export default {
    name: "SciencePolicy",
    data() {
      return {
        // nowIndex: 1,
        typeList: [],//上方标签列表
        currentTypeId: -1,//当前选中的标签ID
        knowledgeList: [],//左边知识列表
        knowledgeListLoading: true,//左边知识列表是否在加载中loading
        knowledgeListTotalNum: 0,//左边知识列表总共有多少条数据
        pageNum: 1,//分页当前页

      }
    },
    components: {
      patentComponent: newestPatentAndAchievement,
    },
    mounted() {
      this.getTypeList();
    },
    methods: {
      //切换头部标签
      changeTypeId(id) {
        this.currentTypeId = id;
        this.pageNum = 1;
        this.knowledgeListTotalNum = 0;
        this.getKnowledgeListById(id);
      },
      //获取分类标签列表
      async getTypeList() {
        const json = await getKnowledgeType().catch(() => this.knowledgeListLoading = false);
        if (json && json.code === 0) {
          if (json.result && json.result.list && json.result.list.length > 0) {
            this.typeList = json.result.list;

            const tab = this.$route.query.tab;
            if (tab) {
              this.currentTypeId = json.result.list[tab - 1].id
            } else {
              this.currentTypeId = json.result.list[1].id
            }
            this.getKnowledgeListById(this.currentTypeId);
          }
        }
        this.knowledgeListLoading = false;
      },
      //根据文章分类编号获取文章列表
      async getKnowledgeListById(articleTypeId) {
        this.knowledgeListLoading = true;
        let params = {
          pageSize: 10,
          pageNum: this.pageNum,
          articleTypeId: articleTypeId
        }
        const json = await getKnowledgeListById(params).catch(() => this.knowledgeListLoading = false);
        if (json && json.code === 0) {
          if (json.result && json.result.list) {
            this.knowledgeList = json.result.list
            this.knowledgeListTotalNum = json.result.total
          }
        }
        this.knowledgeListLoading = false;
      },
      //分页器改变时的回调
      onPageChange(pageNum) {
        this.pageNum = pageNum;
        this.getKnowledgeListById(this.currentTypeId);
      },
    }
  }
</script>

<style scoped lang="scss">

  /deep/ .ivu-page-item-active {
    background-color: #1890FF;
    color: white !important;
    border: none;
  }

  /deep/ .ivu-page-item-active a {
    color: white !important;
  }

  .san_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    padding-right: 100px;
  }

  .content_header span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }

  .per_content div {
    margin-bottom: 5px;
  }

  .yi_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .center {
    width: 1200px;
    margin: 0 auto;
  }

  .bq_classify {
    & span {
      width: 114px;
      margin: 0 auto;
      display: block;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid rgba(220, 220, 220, .5);
      cursor: pointer;
    }
  }

  .bq_select_span {
    border-bottom: none !important;
  }

  .div_classify {
    width: 100%;
    border-bottom: 1px solid rgba(220, 220, 220, .5);
    height: 50px;

    & span {
      display: inline-block;
      margin-right: 20px;
      position: relative;
      cursor: pointer;
    }
  }

  .span_select:after {
    position: absolute;
    content: "";
    width: 40px;
    height: 2px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fc7c40;
  }

  .span_select {
    color: #fc7c40;
  }

  .bq_select {
    color: white;
    background-color: #fc7c40;
  }
</style>
