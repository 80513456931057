<template>
    <!--右边区域-->
    <div style="float: left;width: 330px;">
        <!-- <div style="height: auto;border:1px solid rgba(220, 220, 220, .5);margin-bottom: 30px;background-color: white">
            <div
                    style="height: 50px;line-height:50px;padding-left: 15px;font-size: 14px;border-bottom: 1px solid rgba(220, 220, 220, .5)">
            <span
                    style="display: inline-block;background-color: #1890FF;width: 3px;height: 14px;vertical-align: text-bottom"></span>
                最新专利
            </div>
            <div style="padding-top: 20px">
                <router-link v-for="(item) in newestPatentList" :key="item.id"
                             :to="{path: 'good',query:{'goodId':item.id}}" target="_blank">
                    <div class="per_content"
                         style="font-size:14px;padding:18px 20px;width: 300px;margin: 0 auto;border:1px solid rgba(220, 220, 220, .5);margin-bottom: 20px;cursor: pointer">
                        <div><span class="ellipse-1 npaa-font">{{item.an}}</span></div>
                        <div><span class="ellipse-2 npaa-font">{{item.name}}</span></div>
                        <div class="ellipse-2 npaa-font" style="height: 40px"><span>{{item.siteName}}</span></div>
                        <div class="npaa-font" style="font-size: 12px;color: #666666">申请日期：{{item.createdTime}}</div>
                    </div>
                </router-link>


            </div>
        </div> -->
        <div style="height: auto;background-color: white;margin-bottom: 30px;border-radius:10px;box-shadow:1px 1px 11px 1px #e6eff7">
            <div style="height: 50px;line-height:50px;padding-left: 20px;font-size: 18px;color:#333333;font-weight:bold">
                最新成果
            </div>
            <div style="padding-top: 20px">
                <div class="per_content" v-for="(item) in newestAchievementList" :key="item.id" style="padding-bottom:17px;margin: 0 20px 20px 20px;border-bottom: 1px dashed #D8D8D8;">
                    <router-link :to="{path:'/home/PatentSearchDetail',query:{id:item.id}}" target="_blank">
                        <div class="ellipse-1 npaa-title" :title="item.title"> {{item.title}}</div>
                        <div class="ellipse-1 npaa-font">
                            归属高校：
                            {{item.colleges}}
                        </div>
                        <div class="ellipse-1 npaa-font">
                            应用方向：
                            {{item.category}}
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getNewestAchievementsList, getNewestPatentList} from "../../plugins/api/encyclopediaKnowledgeApi";

    export default {
        name: '',
        data() {
            return {
                newestAchievementList: [],//最新成果列表
                newestPatentList: [],//最新专利列表
            }
        },
        mounted() {
            this.getNewestPatentList();
            this.getNewestAchievementList()
        },
        methods: {
            //获取最新专利列表
            async getNewestPatentList() {
                const params = {
                    pageNum: 1,
                    pageSize: 5
                }
                const json = await getNewestPatentList(params);
                if (json && json.code === 0) {
                    if (json.result && json.result.list && json.result.list.length > 0) {
                        this.newestPatentList = json.result.list;

                    }
                }
            },
            //获取最新成果列表
            async getNewestAchievementList() {
                const params = {
                    pageNum: 1,
                    pageSize: 5
                }
                const json = await getNewestAchievementsList(params);
                if (json && json.code === 0) {
                    if (json.result && json.result.list && json.result.list.length > 0) {
                        this.newestAchievementList = json.result.list
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">

/deep/ .ivu-page-item-active {
  background-color: #1890FF;
  color: white !important;
  border: none;
}

/deep/ .ivu-page-item-active a {
  color: white !important;
}
    .per_content div {
        margin-bottom: 5px;
    }

    .npaa-font {
        font-size: 14px;
        color:#859BBD
    }
    .npaa-title{
        font-size: 16px;
        color: #333333;
    }
    .per_content:last-child{
        border: none !important;
    }
    .per_content:hover{
        .npaa-title {
            color: #1767E0;
        }
    }

</style>
